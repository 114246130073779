/* ----- */

const requests = {
  GET_LOGINDATA: "User",
  GET_PATIENTREGISTER: "Registration",
  UPLOAD_DOC: "Registration/Upload",
  GET_PATIENT: "Registration/:id",
};

export { requests };
