import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import apiManager from "../api/apiManager";
import { requests } from "../api";
import {  useNavigate } from "react-router-dom";
import NavBar from "../components/Navbar";
import { BeatLoader } from "react-spinners";
const ReportsPage = () => {

  const navigate = useNavigate();
  
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleImageClick = (imageUrl) => {
    window.open(imageUrl.file, "_blank");
  };

  // const handleSaveChanges = (reportId, newName) => {
  //   // Update the report in the reportsData state with the new name
  //   setReportsData((prevReportsData) =>
  //     prevReportsData.map((report) =>
  //       report.id === reportId ? { ...report, name: newName } : report
  //     )
  //   );
  // };

  const requestSearch = (searchedVal) => {
    setSearchTerm(searchedVal);
    //   if (searchedVal.length < 1) {
    //     setUploadedFiles(originalData); // Show the original data when search input is empty
    //   } else {
    //     const filteredRows = originalData.filter((row) => {
    //       return (row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())||
    //       row.sex.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())||
    //       row.mobileNo.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())
    //     )
    //     });
    //     setUploadedFiles(filteredRows);
    //   }
  };

  useEffect(() => {
    if (searchTerm.length < 1) {
      setUploadedFiles(originalData); // Show the original data when search input is empty
    } else {
      const filteredRows = originalData.filter((row) => {
        return (
          row.name
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
          row.sex.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
          row.mobileNo
            .toString()
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        );
      });
      setUploadedFiles(filteredRows);
    }
  }, [searchTerm, originalData]);

  // Filter the table data based on the search term

  // const handleFileUpload = (reportId) => {
  //   setSelectedReportId(reportId);
  //   setIsPopupOpen(true);
  // };

  // const handleViewReport = (patientId) => {
  //   // Redirect to the patient report page with the patient ID
  //   history.push(`/patient/${patientId}`);
  // };

  // const handleFileChange = (e) => {
  //   //   const file = e.target.files[0];
  //   // setSelectedFile(file);
  //   setSelectedFiles(Array.from(e.target.files));
  // };
  const handleSubmit = () => {
    const token = localStorage.getItem('jwttoken'); // Replace with your actual token

  const headers = {
    Authorization: `Bearer ${token}`,
  };
    apiManager
      .get(requests.GET_PATIENTREGISTER,{headers})
      .then((response) => {
        console.log(response.status);
        // setReportsData(response.data.data);
        setUploadedFiles(response.data.data);
        setOriginalData(response.data.data);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          // Navigate to login page and delete token from local storage
          localStorage.removeItem('jwttoken');
          // Replace 'login' with the path of your login page
          window.location.href = '/';
        } else {
          console.log("fff", e.message);
          setIsLoading(false);
        }
      });
  };
  useEffect(() => {
    
    handleSubmit();
  }, []);

  // const handleFileSubmit = async () => {
  //   try {
  //     const formData = new FormData();
  //     selectedFiles.forEach((file) => {
  //       formData.append("file", file);
  //     });
  //     let api = requests.UPLOAD_DOC + `/${selectedReportId}`;
  //     console.log(api);
  //     apiManager
  //       .post(api, formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           Accept: "application/json",
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response.data);
  //       })
  //       .catch((e) => console.log(e.message));

  //     // Create new report objects with the uploaded image URLs and new IDs
  //     // const newReports = response.data.urls.map((imageUrl, index) => ({
  //     //   id: selectedReportId + index,
  //     //   patientName: "New Patient",
  //     //   age: 0,
  //     //   number: "",
  //     //   sex: "",
  //     //   date: "",
  //     //   image: imageUrl,
  //     // }));

  //     //   // Update the uploadedFiles state by adding the new reports
  //     // setUploadedFiles((prevUploadedFiles) => [
  //     //   ...prevUploadedFiles,
  //     //   ...newReports,
  //     // ]);
  //     setIsPopupOpen(false);
  //   } catch (error) {
  //     console.error("Error uploading files:", error);
  //   }
  // };
  const getRoute = (item) => {
    navigate(`/patientreport/${item.id}`, { state: { item } });
  };
  return (
    <>
    <NavBar/>
    <div className="min-h-full mb-8 fixed w-full   bg-cover bg-center " style={{ backgroundImage: "url('https://www.italjmed.org/public/journals/1/homepageImage_en_US.jpg')" }}>
    <div className="  p-2 pt-20  h-screen mb-2" >
      {isLoading ? ( // Show loader while isLoading is true
       <>  <div className="flex justify-center mb-4">
       <BeatLoader color="black" loading={true} />
     </div></>
      ) : (
        <>
            
          <div className="flex items-end justify-between mb-2 ">
            <input
              type="text"
              placeholder="Search patient..."
              className="p-2 border border-gray-300 rounded-lg focus:outline-none"
              onChange={(e) => requestSearch(e.target.value)}
            />
          </div>
          <div className="w-full " style={{ maxHeight: '90%', overflowY: 'auto' }}>
          <Table className=" border-collapse shadow-current shadow w-fit h-full"
           >
          
            <Thead className="bg-gray-400">
              <Tr>
                <Th className="border-2">Sr. No</Th>
                <Th className="border-2">Patient Name</Th>
                <Th className="border-2">Age</Th>
                <Th className="border-2">Number</Th>
                <Th className="border-2">Sex</Th>
                <Th className="border-2">Report Status</Th>
                {/* <Th className="border">Image</Th> */}
                <Th className="border-2">Action</Th>
              </Tr>
            </Thead>

            <Tbody
              className="  bg-slate-100"
              // style={{ maxHeight: "82vh",minHeight: "100vh", overflowY: "auto" }}
            >
              {uploadedFiles.map((report,index) => (
                <Tr key={report.id}>
                  <Td className="border-2 text-center">{index+1}</Td>
                  <Td className="border-2 text-center">{report.name}</Td>
                  <Td className="border-2 text-center">{report.age}</Td>
                  <Td className="border-2 text-center">{report.mobileNo}</Td>
                  <Td className="border-2 text-center">{report.sex}</Td>
                  <Td className="border-2 text-center">
                    {report.document.length > 0 ? (
                      <span className="text-green-500">Report uploaded</span>
                    ) : (
                      <span className="text-red-500">Pending</span>
                    )}
                  </Td>
                  {/* <Td className="border">{report.date}</Td> */}
                  {/* <Td className="border">
  {report.document.map((imageUrl, index) => (
    <div key={index}>
      {imageUrl.contentType === "application/pdf" ? (
       <span> <FontAwesomeIcon icon={faFilePdf} className="mr-2 text-red-500" onClick={() => handleImageClick(imageUrl)} /><p>Download here </p></span>
      ) : (
        <img
          src={imageUrl.file}
          alt="Patient Report"
          className="max-w-40 h-40"
          onClick={() => handleImageClick(imageUrl)}
        />
      )}
    </div>
  ))}
</Td> */}
                  <Td className="border-2 text-center">
                    {/* <Link to={`/patientreport/${report.id}`}> */}
                    <button
                      className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                      onClick={() => {
                        getRoute(report);
                      }}
                    >
                      View Report
                    </button>
                    {/* </Link> */}
                    {/* <button
                    onClick={() => handleFileUpload(report.id)}
                    className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded"
                  >
                    Upload
                  </button> */}
                    {/* {report.isEdit ? (
                    <button
                      onClick={() => handleSaveChanges(report.id, report.name)}
                      className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      onClick={() =>
                        setReportsData((prevReportsData) =>
                          prevReportsData.map((prevReport) =>
                            prevReport.id === report.id
                              ? { ...prevReport, isEdit: true }
                              : prevReport
                          )
                        )
                      }
                      className="bg-yellow-500 hover:bg-yellow-600 text-white px-4 py-2 rounded"
                    >
                      Edit
                    </button>
                  )} */}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          </div>
        </>
      )}

      {/* {isPopupOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
          <div className="bg-white p-4 rounded shadow">
            <h2 className="text-lg font-medium mb-4">Upload Report</h2>
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="mb-4"
            />
            {selectedFile && (
              <div className="mb-4">
                {selectedFile.type.includes("image") ? (
                  <img
                    src={URL.createObjectURL(selectedFile)}
                    alt="Selected Imageaa"
                    className="w-1/2 h-1/2 "
                  />
                ) : (
                  <iframe
                    src={URL.createObjectURL(selectedFile)}
                    title="Selected PDF"
                    className="w-full h-64"
                  ></iframe>
                )}
              </div>
            )}
            <div className="flex justify-between">
              <button
                onClick={() => setIsPopupOpen(false)}
                className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleFileSubmit}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div> </div></>
  );
};

export default ReportsPage;
