import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../components/Navbar";
import { requests } from "../api";
import apiManager from "../api/apiManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

const PatientReport = () => {
  const [data, setData] = useState({});
  const [id, setID] = useState(0);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const params = useLocation();

  const token = localStorage.getItem('jwttoken'); // Replace with your actual token

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleImageClick = (imageUrl) => {
    window.open(imageUrl.file, "_blank");
  };
  useEffect(() => {
    console.log(params.state.item);
    setID(params.state.item.id);
    setData(params.state.item);
  }, [params]);

  const bindData = () => {
    apiManager
      .get(requests.GET_PATIENTREGISTER, {headers})
      .then((response) => {
        console.log(response.data.data);
        // setReportsData(response.data.data);
        setData(
          response.data.data.filter(function (el) {
            return el.id === id;
          })[0]
        );
      })
      .catch((e) => {
        console.log("fff", e.message);
      });
    setSelectedFile(null);
  };

  const handleFileUpload = (reportId) => {
    setSelectedReportId(reportId);
    setIsPopupOpen(true);
  };

  const handleFileSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);
      let api = requests.UPLOAD_DOC + `/${selectedReportId}`;

      // Update the status of the report in the data state
      // setData((prevData) => ({
      //   ...prevData,
      //   document: ["uploading"], // Set the initial status to 'uploading'
      // }));

      apiManager
        .post(api, formData, {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
          bindData();
          // Update the status of the report in the data state
          // setData((prevData) => ({
          //   ...prevData,
          //   document: response.data.urls,
          // }));
        })
        .catch((e) => {
          console.log(e.message);

          // Reset the status to empty array if there's an error
          setData((prevData) => ({
            ...prevData,
            document: [],
          }));
        });

      setIsPopupOpen(false);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <>
      <NavBar />
      <div className="min-h-screen  pb-4 pt-20 bg-cover bg-center bg-fixed"
    style={{
      backgroundImage: "url('https://www.italjmed.org/public/journals/1/homepageImage_en_US.jpg')",
    }}>
      <div className="p-8 mx-auto max-w-2xl   bg-slate-200">
        <h1 className="text-2xl font-bold">Patient Report</h1>

        <div className="mt-4 grid grid-cols-2">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="font-bold">Name :</span>
          </label>
          <p id="name" className="text-lg">
            {data.name}
          </p>
        </div>

        <div className="mt-4 grid grid-cols-2">
          <label
            htmlFor="age"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="font-bold">Age :</span>
          </label>
          <p id="age" className="text-lg">
            {data.age}
          </p>
        </div>

        <div className="mt-4 grid grid-cols-2">
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="font-bold">Mobile No :</span>
          </label>
          <p id="mobile" className="text-lg">
            {data.mobileNo}
          </p>
        </div>

        <div className="mt-4 grid grid-cols-2">
          <label
            htmlFor="sex"
            className="block text-sm font-medium text-gray-700"
          >
            <span className="font-bold">Sex :</span>
          </label>
          <p id="sex" className="text-lg">
            {data.sex}
          </p>
        </div>

        {/* <div className="mt-8">
          <label
            htmlFor="reportStatus"
            className="block text-sm font-medium text-gray-700"
          >
            Report Status
          </label>
          {data.document?.length > 0 ? (
            <span className="text-green-500">Report uploaded</span>
          ) : (
            <span className="text-red-500">Pending</span>
          )}
        </div> */}
        {data.document?.length > 0 && (
          <div className="mt-8">
            <h2 className="text-lg font-medium text-gray-700 mb-2">
              Your Report :
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
              {data.document.map((imageUrl, index) => (
                <div key={index} className="flex items-center">
                  {imageUrl.contentType === "application/pdf" ? (
                    <div className="flex items-center">
                      <FontAwesomeIcon
                        icon={faFilePdf}
                        className="mr-2 text-red-500"
                        onClick={() => handleImageClick(imageUrl)}
                      />
                      <a
                        href={imageUrl.file}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500"
                      >
                        Download PDF
                      </a>
                    </div>
                  ) : (
                    <img
                      src={imageUrl.file}
                      alt="Patient Report"
                      className="max-w-full  cursor-pointer h-56"
                      onClick={() => handleImageClick(imageUrl)}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        <div className="mt-8">
          <button
            onClick={() => handleFileUpload(data.id)}
            className="bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-2 rounded"
          >
            Upload
          </button>
        </div>

        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white p-4 rounded shadow">
              <h2 className="text-lg font-medium mb-4">Upload Report</h2>
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg, application/pdf"
                onChange={handleFileChange}
                className="mb-4"
              />
              {selectedFile && (
                <div className="mb-4">
                  {selectedFile.type && selectedFile.type.includes("image") ? (
                    <img
                      src={URL.createObjectURL(selectedFile)}
                      alt="Selected Image"
                      style={{ width: "300px" }}
                      className="w-1/2 h-1/2"
                    />
                  ) : (
                    <iframe
                      src={URL.createObjectURL(selectedFile)}
                      title="Selected PDF"
                      className="w-full h-64"
                    ></iframe>
                  )}
                </div>
              )}
              <div className="flex justify-between">
                <button
                  onClick={() => setIsPopupOpen(false)}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handleFileSubmit}
                  className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      </div>
    </>
  );
};

export default PatientReport;
