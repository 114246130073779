import React ,{useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { BeatLoader } from "react-spinners";
import apiManager from "../api/apiManager";
import { requests } from "../api/requests";
import NavBar from "../components/Navbar";

const registrationSchema = Yup.object().shape({
  name: Yup.string().min(3, "Too Short!").required("Name is required"),
  age: Yup.number().required("Age is required").positive("Invalid age"),
  mobileNo: Yup.string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Invalid phone number")
    .required("Mobile number is required"),
  sex: Yup.string().required("Sex is required"),
});

const RegistrationForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  
  const initialValues = {
    age: "",
    sex: "",
    mobileNo: "",
    name: "",
  };
  const notify = () => toast("Register successfully");
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);
    const token = localStorage.getItem('jwttoken'); // Replace with your actual token

  const headers = {
    Authorization: `Bearer ${token}`,
  };

    try {
      const response = await apiManager.post(requests.GET_PATIENTREGISTER, values,{
        headers: headers,
      });

      if (response.data.message === "Succes") {
        toast.success("Register successfully");
        resetForm();
      } else if (!response.data.isSuccess) {
        toast.info("Registration is pending");
        resetForm();
      } else if (response.data.message === "Rejected") {
        toast.error("Registration rejected");
        resetForm();
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
    }
  };
  //   try {
  //     const response = await axios.post("/register", values, {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`, // Include the JWT token in the request headers
  //       },
  //     });
  //     console.log(response.data); // Handle the server response
  //     resetForm();
  //   } catch (error) {
  //     console.error("Error registering:", error);
  //   }
  // };

  return (
    <>
    <NavBar/>
    <div className="pt-20  h-screen    bg-cover bg-center"
    style={{
      backgroundImage: "url('https://www.italjmed.org/public/journals/1/homepageImage_en_US.jpg')",
    }}>
     {isLoading ? (
        <BeatLoader color="black" loading={true} />
      ) : (
    <Formik
      initialValues={initialValues}
      validationSchema={registrationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="max-w-md mx-auto  p-4 bg-gray-100 rounded-md  ">
        <div className="mb-3">
          <div>
          
            <ToastContainer autoClose={3000} />
          </div>
          <div className="mb-3 text-center">
            <h1 className="text-2xl font-bold mt-1 mb-4">
              Patient Registration
            </h1>
          </div>
          <label className="font-semibold" htmlFor="name">
            Name:
          </label>
          <Field
            type="text"
            id="name"
            name="name"
            className="block w-full border-gray-300 rounded-md p-2"
          />
          <ErrorMessage
            name="name"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>

        <div className="mb-3">
          <label className="font-semibold" htmlFor="age">
            Age:
          </label>
          <Field
            type="number"
            id="age"
            name="age"
            className="block w-full border-gray-300 rounded-md p-2"
          />
          <ErrorMessage
            name="age"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>

        <div className="mb-3">
          <label className="font-semibold" htmlFor="mobile">
            Mobile:
          </label>
          <Field
            type="text"
            id="mobileNo"
            name="mobileNo"
            className="block w-full border-gray-300 rounded-md p-2"
          />
          <ErrorMessage
            name="mobileNo"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>

        <div className="mb-3">
          <label className="font-semibold" htmlFor="sex">
            Sex:
          </label>
          <Field
            as="select"
            id="sex"
            name="sex"
            className="block w-full border-gray-300 rounded-md p-2"
          >
            <option value="">Select</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="Other">Other</option>
          </Field>
          <ErrorMessage
            name="sex"
            component="div"
            className="text-red-500 text-sm"
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded-md"
        >
          Register
        </button>
      </Form>
    </Formik> 
    )}
   </div>
    </>
  );
};

export default RegistrationForm;
