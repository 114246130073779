import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import LoginForm from "./pages/LoginForm";
import RegisterPatient from "./pages/RegisterPatient";
import NavBar from "./components/Navbar";
import Repots from "./pages/Repots";
import PatientReport from "./pages/PatientReport";

const App = () => {
  // const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const value = localStorage.getItem("jwttoken");
    if (value) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);
  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  // const handleLogout = () => {
  //   localStorage.clear();
  //   setIsLoggedIn(false);

  // };
  // {isLoggedIn ? (
  //   <Navigate to={"/register"} />
  // ) : (
  //   <LoginForm onLogin={handleLogin} />
  //   // <LoginForm onLogin={handleLogin} />
  // )}
  return (
    <>
      <Router>
        {/* {isLoggedIn && <NavBar onLOgOut={handleLogout} />} */}

        <Routes>
          {/* <Route exact path='home' element={<Home/>}></Route> */}
          <Route exact path="/" element={<LoginForm onLogin={handleLogin} />} ></Route>
          <Route exact path="/register" element={<RegisterPatient />}></Route>
          <Route exact path="/patientlist" element={<Repots />}></Route>
          <Route exact path="/patientreport/:id" element={<PatientReport />}></Route>
        </Routes>
      </Router>
    </>
  );
};

export default App;
