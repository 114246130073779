import React, { useState,useEffect } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import apiManager from "../api/apiManager";
import { requests } from "../api/requests";
import { useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LoginForm = ({onLogin}) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const initialValues = {
    loginID: "",
    password: "",
  };
  const showToastMessage = () => {
    
    toast.error('Login Failed ! please check ID and Password', {
        position: toast.POSITION.TOP_RIGHT
    });
};


  useEffect(() => {
    const token = localStorage.getItem('jwttoken');
    if (token) {
      // Redirect to another page if token exists
      navigate('/register'); // Replace '/dashboard' with your desired page
    }
  }, [navigate]);

  const validationSchema = Yup.object().shape({
    loginID: Yup.string().required("ID is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    setLoading(true);
    apiManager
      .post(requests.GET_LOGINDATA, values)
      .then((response) => {
        if (response.data.isSuccess) {
          setLoading(false);
          

          setSubmitting(false);
          localStorage.setItem("jwttoken", response.data.data.token);
          console.log("Login successful");

          navigate("/register");
          onLogin();
        } else {
          showToastMessage()
          setLoading(false);
          navigate("/");
          console.log("Login failed");
        }
      })
      .catch((e) => console.log(e.message));
  };

  return (
    <div className="flex justify-center items-center  h-screen bg-cover bg-center"
    style={{
      backgroundImage: "url('https://www.italjmed.org/public/journals/1/homepageImage_en_US.jpg')",
    }}>
    <div className="w-full max-w-xs">
    <ToastContainer />
      {loading ? (
        <div className="flex justify-center mb-4">
          <BeatLoader color="black" loading={true} />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          <Form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-center text-xl font-bold mb-4">Login</h2>
            <div className="mb-4">
              <label
                htmlFor="id"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                ID
              </label>
              <Field
                type="text"
                id="id"
                name="loginID"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="loginID"
                component="p"
                className="text-red-500 text-xs italic"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="password"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Password
              </label>
              <Field
                type="password"
                id="password"
                name="password"
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
              <ErrorMessage
                name="password"
                component="p"
                className="text-red-500 text-xs italic"
              />
            </div>
            <div className="flex items-center justify-between">
              <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Sign In
              </button>
            </div>
          </Form>
        </Formik>
      )}
    </div> </div>
  );
};

export default LoginForm;
